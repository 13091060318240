<template>
  <main class="product-image">
    <header class="notifier-header">
      <h1 class="notifier-title">{{ $t('intro') }}</h1>
      <h1 class="notifier-title2">{{ $t('intro2') }}</h1>
    </header>
    <section class="notifier-content">
      <h2 class="notifier-subtitle">
        {{ $t('header1') }} <span class="gradient-text"> {{ $t('header2') }}</span>
      </h2>
    </section>

    <!-- Video Section -->
    <section class="video-section">
      <video autoplay loop muted playsinline class="promo-video">
        <source src="../../public/fbmpgif.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </section>

    <!-- Pricing Plans Section -->
    <section class="pricing-plans">
      <h2 class="notifier-subtitle"> {{ $t('choose_plan') }}</h2>
      <h1 class="notifier-title3">{{ $t('plan_instructions') }}</h1>
      <div class="plans-container">

        <!-- Pro Plan -->
        <div class="plan-card">
          <h3 class="plan-title">{{ $t('plan_title_0') }} <span class="gradient-text">{{ $t('plan_title_1') }}</span></h3>
          <p class="plan-price">{{ $t('and') }}</p>
          <p class="plan-price">{{ $t('plan_price') }}</p>
          <ul class="plan-features">
            <li class="gradient-text"><strong>{{ $t('pro_li_1') }} </strong></li>
            <li><strong>{{ $t('pro_li_2') }}</strong></li>
            <li><strong>{{ $t('pro_li_3') }}</strong></li>
            <li><strong>{{ $t('pro_li_4') }}</strong></li>
          </ul>
          <div id="paypal-button-pro"></div>
        </div>

      </div>
    </section>

    <img
      src="../../public/fbmp_logo.png"
      alt="Circle product features"
      class="features-image"
    />

    <section class="notifier-pricing">
      <h2> <span class="gradient-text">{{ $t('aboutUsHeader') }}</span></h2>
      <p class="notifier-title">
        {{ $t('aboutUsContent') }}
      </p>
      <a href="https://www.instagram.com/marketplace_ads_renew/" target="_blank" rel="noopener noreferrer">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="instagram-icon"><rect x="2" y="2" width="20" height="20" rx="5" ry="5"></rect><path d="M16 11.37A4 4 0 1 1 12.63 8 4 4 0 0 1 16 11.37z"></path><line x1="17.5" y1="6.5" x2="17.51" y2="6.5"></line></svg>
      </a>
    </section>
    <footer  class="notifier-pricing">
      <h2> <span class="footer">&copy; 2024 Marketplace Ads Renew.</span></h2>
      <div class="footer-links">
        <a @click="showPrivacyPolicy" class="footer-link">Privacy Policy</a> | 
        <a @click="showTerms" class="footer-link">Terms and Conditions</a>
      </div>
    </footer>

  <!-- Privacy Policy Modal -->
  <div v-if="showPrivacy" class="modal-overlay">
    <div class="modal">
      <h2>Privacy Policy</h2>
      <p>
        At Marketplace Ads Renew, your privacy is important to us. This Privacy Policy outlines how we collect, use, and protect your personal information.
      </p>
      <ul>
        <li><b>Information We Collect:</b> Personal details (e.g., name, email), usage data.</li>
        <li><b>How We Use Your Information:</b> Process purchases, send notifications, improve services.</li>
        <li><b>Data Protection:</b> Strict security measures, no sharing/selling to third parties.</li>
        <li><b>Cookies:</b> Enhance user experience, provide analytical insights.</li>
        <li><b>Your Rights:</b> Access, modify, or delete your data upon request.</li>
      </ul>
      <p>For further inquiries, contact us at <a href="mailto:marketplaceadsrenew@gmail.com">marketplaceadsrenew@gmail.com</a>.</p>
      <button @click="closeModal">Close</button>
    </div>
  </div>

  <!-- Terms and Conditions Modal -->
  <div v-if="showTermsModal" class="modal-overlay">
    <div class="modal">
      <h2>Terms and Conditions</h2>
      <p>
        Welcome to Marketplace Ads Renew! By accessing or using our service, you agree to the following terms:
      </p>
      <ul>
        <li><b>License Agreement:</b> Non-transferable license for one computer.</li>
        <li><b>User Responsibilities:</b> Maintain confidentiality of your license key. No misuse or unauthorized sharing.</li>
        <li><b>Payments and Refunds:</b> All purchases are final. Refunds only as required by law.</li>
        <li><b>Service Limitations:</b> Provided "as is," without guarantees of uninterrupted operation.</li>
        <li><b>Changes to Terms:</b> Updates may occur; continued use indicates acceptance.</li>
      </ul>
      <p>For questions, contact us at <a href="mailto:marketplaceadsrenew@gmail.com">marketplaceadsrenew@gmail.com</a>.</p>
      <button @click="closeModal">Close</button>
    </div>
  </div>

  </main>
</template>

<script>
/* global paypal */
export default {
  data() {
    return {
      showPrivacy: false,
      showTermsModal: false,
    };
  },
  name: 'FbmpContent',
  props: {
    redirectToSuccess: {
      type: Function,
      required: true
    }
  },
  mounted() {
    const script = document.createElement('script');
    const clientId = process.env.VUE_APP_PAYPAL_CLIENT_ID;
    script.src = `https://www.paypal.com/sdk/js?client-id=${clientId}&vault=true&intent=subscription&locale=en_US`;
    script.onload = this.loadPayPalButtons;
    document.body.appendChild(script);
  },
  methods: {
    showPrivacyPolicy() {
      this.showPrivacy = true;
    },
    showTerms() {
      this.showTermsModal = true;
    },
    closeModal() {
      this.showPrivacy = false;
      this.showTermsModal = false;
    },
    loadPayPalButtons() {
      const p_pro = process.env.VUE_APP_PAYPAL_PLAN_PRO
      const plans = [
        { id: 'paypal-button-pro', planId: `${p_pro}` }
      ];

      plans.forEach(plan => {
        paypal.Buttons({
          createSubscription: function(data, actions) {
            return actions.subscription.create({
              'plan_id': plan.planId
            });
          },
          onApprove: (data, actions) => {
            actions.subscription.get().then(details => {
              const { given_name, surname } = details.subscriber.name;
              const email = details.subscriber.email_address;
              const countryCode = details.subscriber.shipping_address?.address?.country_code;

              fetch('https://fbmp-latest.onrender.com/new-subscription', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                  subscription_id: data.subscriptionID,
                  email,
                  first_name: given_name,
                  last_name: surname,
                  country: countryCode || 'unknown'
                })
              })
                .then(response => response.json())
                .then(() => this.redirectToSuccess())
                .catch(error => console.error('Error:', error));
            });
          }
        }).render(`#${plan.id}`);
      });
    }
  }
};
</script>

<style scoped>
.video-section {
  text-align: center;
  margin: 80px 0;
}

.promo-video {
  width: 100%;
  transform: scale(1);
  max-width: 400px; /* Adjust to fit your design */
  border-radius: 8px; /* Optional: rounded corners */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Optional: add some shadow */
}

.footer-links {
  margin-top: 10px;
  font: 400 15px/1.56 'Plus Jakarta Sans', 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
  text-align: center;
}

.footer-link {
  color: #004bdd;
  cursor: pointer;
  text-decoration: underline;
  margin: 0 5px;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  font: 400 15px/1.56 'Plus Jakarta Sans', 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
}

.modal {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  max-width: 500px;
  text-align: left;
}

.modal button {
  margin-top: 15px;
  padding: 8px 16px;
  background: #004bdd;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}


.pricing-plans {
  text-align: center;
  margin: 50px auto;
  width: 100%; /* Ensure it spans the full width */
}

/* Plans Container */
.plans-container {
  display: flex;
  justify-content: center; /* Center the cards horizontally */
  align-items: flex-start; /* Align cards to the top */
  flex-wrap: nowrap; /* Allow cards to wrap on smaller screens */
  gap: 20px; /* Space between cards */
  margin: 20px auto;
  max-width: 1200px; /* Limit the overall container width */
  padding: 0 20px;
}

/* Plan Card */
.plan-card {
  flex: 1 1 calc(33.333% - 40px); /* Divide space evenly for 3 cards */
  max-width: 300px; /* Ensure a maximum card width */
  min-width: 250px; /* Ensure cards don’t shrink too much */
  box-sizing: border-box; /* Include padding/border in width */
  text-align: center; /* Align text inside the card */
  padding: 20px; /* Optional: add padding inside the card */
  border: 1px solid #ddd; /* Optional: add a light border */
  border-radius: 8px; /* Optional: rounded corners */
  background-color: #fff; /* Optional: add a background */
  font-family: 'Plus Jakarta Sans', 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
}

.special-offer {
  color: #ff4500;
}


  .plan-title {
    font-size: 1.5rem;
    margin-bottom: 10px;
  }

  .plan-price {
    font-size: 1.2rem;
    margin-bottom: 20px;
  }

  .plan-features {
    list-style: none;
    padding: 0;
  }

  .plan-features li {
    margin-bottom: 10px;
  }

  .product-image {
    aspect-ratio: 1.96;
    width: 627px;
    border-radius: 10px;
    margin-top: 88px;
    max-width: 100%;
    text-align: center; /* Center the image */
  }
  
  .paypal-button {
    max-width: 500px; 
    margin: 0 auto;  
  }

  .features-image {
    aspect-ratio: 1.64;
    width: 100%; /* Make the image full width */
    height: auto; /* Maintain aspect ratio */
    z-index: 10;
    margin: 68px 0; /* Adjust margins */
    max-width: none; /* Override max-width */
    position: relative; /* To use z-index */
  }
  
  .notifier-container {
    display: flex;
    width: 573px;
    max-width: 100%;
    flex-direction: column;
    align-items: center;
    margin: 99px 0 256px;
  }
  
  .notifier-title {
    color: #52525b;
    font: 400 25px/1.56 'Plus Jakarta Sans', 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
    text-align: center;
  }

  .notifier-title2 {
    color: #9754ad;
    font: 400 18px/1.56 'Plus Jakarta Sans', 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
    text-align: center;
  }

  .notifier-title3 {
    /* color: #3b989e;  */
    color: #049682;
    font: 400 18px/1.56 'Plus Jakarta Sans', 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
    text-align: center;
  }

  .notifier-header {
    width: 100%;
  }
  
  .instagram-icon {
    margin-top: 10px;
    width: 30px;
    height: 30px;
    fill: #f5f7fa; /* Change this color as needed */
    display: inline-block;
  }
  
  .notifier-content {
    width: 100%;
    text-align: center;
  }
  
  .notifier-subtitle {
    margin-top: 36px;
    font: 400 65px/1.19 'Plus Jakarta Sans', 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  .gradient-text {
    background: linear-gradient(92deg, #ff00b8 67.57%, #004bdd 89.21%, #8f00ff 109.27%);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  
  .notifier-pricing {
    color: #71717a;
    margin-top: 46px;
    font: 26px/1.625 'Plus Jakarta Sans', 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
  }

  .footer {
    color: #71717a;
    margin-top: 26px;
    font: 16px/1.625 'Plus Jakarta Sans', 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
    line-height: 1.4; /* Adjust line height for better spacing */
    text-align: center; /* Center the text */
    padding: 0 15px; /* Add padding to ensure the text isn't too close to the edges */
  }
  
  @media (max-width: 991px) {
    .product-image {
      margin-top: 40px;
    }
  
    .features-image {
      margin: 40px auto; /* Center the image */
    }

    .notifier-container {
      margin: 40px 0;
    }
  
    .plans-container {
      flex-wrap: wrap;
    }

    .notifier-title {
      max-width: 100%;
      padding: 1px;
    }

    .notifier-title2 {
      max-width: 100%;
      padding: 1px;
    }

    .notifier-subtitle {
      max-width: 100%;
      font-size: 40px;
      line-height: 1.325;
    }
  
    .notifier-pricing {
      margin-top: 40px;
    }

    .footer {
      margin-top: 40px;    
    }

    .features-image {
      height: auto;
    }
  }

  @media (max-width: 768px) {
  .plan-card {
    flex: 1 1 calc(50% - 20px); /* Two cards per row on medium screens */
  }
}

@media (max-width: 480px) {
  .plan-card {
    flex: 1 1 100%; /* One card per row on small screens */
  }

  .features-image {
      height: auto;
    }

}

</style>
