import { createApp } from 'vue';
import { createI18n } from 'vue-i18n';
import App from './App.vue';
import { messages } from './locales';

// Detect user's browser language
function detectLanguageFromBrowser() {
  const userLang = navigator.language || navigator.userLanguage;
  console.log('Browser language detected:', userLang);
  return userLang.startsWith('he') ? 'he' : 'en';
}

// Detect user's location using the IP-based API
async function detectLanguage() {
  try {
    const response = await fetch(`https://ipinfo.io/json?token=5422185868e3a9&timestamp=${Date.now()}`);
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const data = await response.json();
    // console.log('API response country:', data.country);

    // Set Hebrew for Israeli users, English for everyone else
    return data.country === 'IL' ? 'he' : 'en';
  } catch (error) {
    console.error('Error detecting language from IP API:', error);
    return detectLanguageFromBrowser(); // Fallback to browser language
  }
}

(async () => {
  const locale = await detectLanguage();
  // console.log('Selected locale:', locale);

  const i18n = createI18n({
    locale: locale || 'en', // Fallback to English if no locale is detected
    fallbackLocale: 'en',
    messages,
  });

  const app = createApp(App);
  app.use(i18n);
  app.mount('#app');

  // Clear storage for clean language detection during testing
  localStorage.clear();
  sessionStorage.clear();

  // Log for debugging purposes
  // console.log('Vue app mounted with locale:', i18n.global.locale);
})();